<template>
  <Dialog :show-close-button="false">
    <div class="max-h-[92vh] flex flex-col px-sm pb-md">
      <div v-if="$siteIdent.includes('minilu')" class="flex justify-center">
        <img
          src="@/assets/images/minilu/minilu_drug_beaver.svg"
          class="h-[120px] relative -top-md"
        />
      </div>
      <Headline
        v-else
        :headline="t('checkout.drugAddressHint.title')"
        level="h4"
        class="max-w-full text-center mt-md mb-sm"
      />
      <div class="h-[calc(92vh-135px)] overflow-auto overscroll-contain">
        <div class="">
          <NotificationBar :type="NotificationType.WARN" class="!mt-0">
            <div class="py-sm">
              {{ t('checkout.drugAddressHint.warnText') }}
            </div>
          </NotificationBar>
          <div class="bg-background-base rounded-alt-lg p-sm mt-sm">
            <div v-if="useCartStore().drugItems.length > 1" class="font-bold">
              {{ useCartStore().drugItems.length }}&nbsp;{{
                t('checkoutSubView.cart.articleCount')
              }}
            </div>
            <div
              v-if="useCartStore().drugItems.length > 1"
              class="h-[1px] w-full bg-border-light my-sm"
            ></div>
            <ProductList
              class="!mt-0"
              :line-items="useCartStore().drugItems"
              :is-extendable="true"
              :products-interactable="false"
              :with-article-count="false"
              product-frame-color="bg-primary-base-opc10"
            />
          </div>
          <div class="px-sm mt-sm">
            <RadioButton
              :options="radioSelects"
              :model-value="selectedChoice"
              @update:model-value="(newValue) => updateChoice(newValue)"
            >
              <template #label="{ context }">
                <div class="flex flex-col">
                  <span>{{ context.option.label }}</span>
                  <div
                    v-if="
                      context.option.addressInfo &&
                      useUserAddress().contactAddress
                    "
                  >
                    <div class="flex flex-col mt-sm">
                      <span class="font-bold">{{
                        useUserAddress().contactAddress.name1
                      }}</span>
                      <span class=""
                        >{{ useUserAddress().contactAddress.street }}
                        {{ useUserAddress().contactAddress.number }},
                        {{ useUserAddress().contactAddress.zip }}
                        {{ useUserAddress().contactAddress.city }},</span
                      >
                      <span class="">
                        {{ useUserAddress().contactAddress.country }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </RadioButton>
            <BasicButton
              full-width
              class="!w-full mt-sm mb-md md:mb-0"
              :label="t('checkout.drugAddressHint.confirm')"
              @click="handleConfirm"
            />
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import ProductList from '~/components/shop/priceSummary/components/product-list.vue';
import Dialog from '@/components/dialog/components/dialogFrame/empty/dialogFrame.vue';
import useDrugContactChoiceDialog from '@/composables/dialogs/useDrugContactAddressChoiceDialog';
import { useCartStore } from '@/stores/useCart';
import { useUserAddress } from '@/stores/useUserAddress';
import {
  RadioButton,
  NotificationBar,
  NotificationType,
  BasicButton,
  Headline,
} from '@/complib';

const dialog = useDrugContactChoiceDialog();
const { t } = useTrans();

const radioSelects = [
  {
    label: t('checkout.drugAddressHint.select.contact'),
    value: 'contactAddress',
    addressInfo: true,
  },
  {
    label: t('checkout.drugAddressHint.select.drugs'),
    value: 'drugRemoval',
  },
];

const selectedChoice = ref<string>('contactAddress');

function updateChoice(newValue: string) {
  selectedChoice.value = newValue;
}

async function handleConfirm() {
  if (selectedChoice.value === 'contactAddress') {
    await handleSetContactAddress();
  } else {
    await handleRemoveAMClick();
  }
}
async function handleSetContactAddress() {
  await useUserAddress().setActiveAddress({
    shippingAddressId: useUserAddress().contactAddress.id,
  });
  dialog.close();
}

async function handleRemoveAMClick() {
  await useCartStore().removeAllDrugItems();
  dialog.close();
}
</script>
<style scoped lang="postcss">
:deep(.showMoreLessArrow) {
  @apply text-primary-base font-bold text-lg;
}
</style>
